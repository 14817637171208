import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './sun.js'


let app = createApp(App)

app.config.globalProperties.apiEndpoint = "https://tusovka-test-api.drusha.me"
app.config.globalProperties.frontEndpoint = "https://tusovka.pages.dev"
app.config.globalProperties.sharePrefix = "https://t.me/drushabot/testapp?startapp="
app.use(router).mount('#app')