<template>
    <div class="row toastToast">
        <div class="col-1 justify-content-center g-2" style="text-align: center;">
            <h1>{{ emoji }}</h1>
        </div>
        <div class="col-11">
            <div class="d-flex w-100 justify-content-between">
            <b class="title">{{ title }}</b>
            <small v-on:click="clearToasts()"><i class="bi bi-x-circle-fill"></i></small>
            </div>
            <small class="text-muted">{{ message }}</small>
        </div>
    </div>
</template>
<script>
import {clearToasts } from 'mosha-vue-toastify';
    export default {
        props: ['title', 'message', 'emoji'],
        methods: {
            clearToasts(){
                clearToasts();
            }
        }
    }
</script>
<style scoped>
    .toastToast{
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.1rem;
    }
    .title{
        font-size: 1.1rem;
    }
</style>