<template>

<div class="container">
    <div class="row justify-content-center">
    <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-6 mt-3">

        <button @click="$router.go(-1)" class="btn btn-light"><i class="bi bi-arrow-return-left"></i> Назад</button>
        <br>


        <div class="center" v-if="isLoading">
            <div class="d-flex justify-content-center m-5">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        <div class="content" v-if="!isLoading">
        <br>
            <h2>{{text}}</h2>
            <p v-html="descr"></p>
        </div>
    </div>
    </div>
</div>

</template>


<script>

export default ({
    name: 'CardFullTextView',
    setup() {
        
    },
    data(){
        return{
            text: null,
            descr: null,
            isLoading: true,
        }
    },
    props: {
        info: String,
    },
    async mounted(){
        this.getCards();
    },
    methods: {
         async getCards(){
            const res = await fetch(
            this.apiEndpoint+'/?m=listCards'
            );
            const data = await res.json();  
            if(data.response == "success"){
                data.data.forEach(element => {
                    if(element['path'] == this.info){
                        this.text = element['text'];
                        this.descr = element['descr'];
                        this.isLoading = false;
                    }
                })
            }
        },   
    }
})
</script>

<style scoped>

</style>