<template>
    <title-component title="добавить событие"></title-component>

    <div class="center info" v-if="this.event == null && this.loading">
        <br>
        <div class="d-flex justify-content-center m-5">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Загрузка...</span>
            </div>
        </div>
        <br>
        <br>
    </div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-6 mb-3">
                <br>
                <h3>Добавить событие</h3>
                <br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Обязательное поле. Избегайте использования дат и мест.
                </div>
                Название:<br>
                <input type="text" class="form-control" v-model="this.name"><br>

                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Если галочка отмечена - мероприятие отображается в уменьшенном виде.
                </div>
                <div class="row">
                    <div class="col-10">
                        не интересное
                    </div>
                    <div class="col-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="darkTheme"
                                v-model="this.isInteresting">
                        </div>
                    </div>
                </div><br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Обязательное поле.
                </div>
                Дата проведения:<br>
                <VueDatePicker v-model="this.timestamp" uid="demo" model-type="timestamp" :format="formatDatePicker" />
                <br>

                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Не показывается на странице, однако используются при поиске. Чем больше подходящих тегов, тем лучше. Если нет необходимого тега - пропусти и сообщи об этом после.
                </div>
                Теги:<br>
                <vSelect multiple v-if="this.possibleTags != null" :options="this.possibleTags" label="tag"
                    v-model="this.selectedTags">
                </vSelect><br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Если нет необходимого места - пропусти и сообщи об этом после.
                </div>
                Место проведения:<br>

                <vSelect v-if="this.places != null" :options="this.places" label="name" v-model="this.selectedPlace">
                </vSelect><br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Ссылка на более подробную информацию о мероприятии. Telegram, VK, Instagram, что угодно.
                </div>
                Ссылка:<br>
                <input type="text" class="form-control" v-model="this.instagramLink"><br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    При наличии.
                </div>
                Сайт с билетами:<br>
                <input type="text" class="form-control" v-model="this.eventSite"><br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Если бесплатно(или бесплатно с репостом) - прописать free. Если цен несколько, то вписать самую низкую цену с припиской "от".
                </div>
                Прайс:<br>
                <input type="text" class="form-control" v-model="this.price"><br>

                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Можно просто скопировать текст из источника.
                </div>
                Описание:
                <!--
                <i v-if="!this.descriptionEdit" v-on:click="this.descriptionEdit = !this.descriptionEdit"><i
                        class="bi bi-pencil"></i> редактировать</i>
                <i v-if="this.descriptionEdit" v-on:click="this.descriptionEdit = !this.descriptionEdit"><i
                        class="bi bi-binoculars"></i> предпросмотр</i>
                -->
                <br>

                <div v-if="!this.descriptionEdit">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-text">
                                <span class="card-text" v-html="this.eventDescriptionToShow"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <textarea v-if="this.descriptionEdit" class="form-control" v-model="this.description" rows="10">
        </textarea>
                <br>
                <div class="alert alert-dark" role="alert" v-if="this.showAnnotations">
                    Обязательное поле. После загрузки изображения его необходимо обрезать по квадрату.
                </div>
                <div class="center">
                    Изображение:<br>
                    <input type="file" @change="handleFileUpload" />
                    <br>{{ this.newImageId }}<br>
                </div>

                <cropper v-if="this.selectedFileUrl != null" ref="cropper" class="cropper" :src="selectedFileUrl" :stencil-props="{aspectRatio: 1 / 1}" @change="change" />

                <div class="center">
                    <br>
                    <button class="w-100 btn btn-primary btn-lg" disabled v-if="this.selectedFileUrl == null || this.name == null || this.timestamp == null">Необходимо заполнить обязательные поля</button>
                    <button class="w-100 btn btn-primary btn-lg" v-on:click="this.submit()" v-if="this.selectedFileUrl != null && this.name != null && this.timestamp != null">Добавить</button>
                    {{ this.responseData }}
                </div>
                <br>
            </div>
        </div>
    </div>

    <div class="spacer"></div>
</template>

<script>

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import router from '../router';
import TitleComponent from '../components/TitleComponent.vue';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    name: 'AddEventView',
    components: {
        TitleComponent,
        vSelect,
        VueDatePicker,
        Cropper
    },
    data() {
        return {
            event: null,
            name: null,
            lastLoadedPage: null,
            maxPages: null,
            loading: false,
            maxPagesLoaded: false,
            currentTime: null,
            date: null,
            timestamp: null,
            description: null,
            eventDescriptionToShow: null,
            fullImgLink: null,
            isBookmarked: false,
            map: '',
            placeId: 0,
            placeLatitude: '',
            placeLongitude: '',
            placeAddress: '',
            placeName: '',
            placeMetro: '',
            placeMetroColor: '',
            placeMetroTimeWalk: '',
            price: null,
            canShare: false,
            hideMap: false,
            isAdmin: false,
            places: [],
            eventSite: null,
            isShowen: true,
            isInteresting: false,
            token: null,
            responseData: null,
            mapLoad: false,
            clickCount: 0,
            deleteCount: 0,
            darkTheme: false,
            descriptionEdit: true,
            reaction: null,
            firstReacts: 0,
            secondReacts: 0,
            thirdReacts: 0,
            fourthReacts: 0,
            recommended: 0,
            selectedPlace: null,
            selectedPlaceId: 0,
            selectedFile: null,
            selectedFileUrl: null,
            newImageId: null,
            instagramLink: null,
            possibleTags: [],
            selectedTags: [],
            eventTags: [],
            showAnnotations: false,
        }
    },
    watch: {
        placeId: function () {
            if (this.placeId != 0) {
                let element = document.getElementById("place");
                // check if hidden attribute exitst
                element.classList.remove("hidden");
            }
            this.updateSelectedEvent();
        },
        selectedPlace: function (place) {
            if (place) {
                if (place.id != 0) {
                    this.selectedPlaceId = place.id;
                }
            } else {
                this.selectedPlaceId = 0;
            }
        }
    },
    activated() {
        if(localStorage.getItem("showAnnotations") === null || localStorage.getItem("showAnnotations") == "true") {
            this.showAnnotations = true;
        } else {
            this.showAnnotations = false;
            localStorage.setItem("showAnnotations", this.showAnnotations);
        }
    },
    async mounted() {
        this.checkToken();
        if (window.Telegram && window.Telegram.WebApp) {
            this.isTelegram = true;
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })
        }

        this.getPlaces();
        this.getTags();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },
    methods: {
        async getTags() {
            const res = await fetch(
                this.apiEndpoint + '/?m=listTags'
            )
            const data = await res.json();
            if (data.response == "success") {
                this.possibleTags = data.data;
                this.eventTags.forEach(tag => {
                    let a = this.possibleTags.find(t => t.id == tag);
                    this.selectedTags.push(a);
                })
            }
        },
        async checkKey() {
            if (localStorage.getItem("key") != null) {
                this.userKey = localStorage.getItem("key");
            } else {
                const res = await fetch(
                    this.apiEndpoint + '/?m=getKey'
                );
                const data = await res.json();
                if (data.response == "success") {
                    this.userKey = data.data['key'];
                    localStorage.setItem("key", data.data['key']);
                    this.setFCMToken(this.userKey, localStorage.getItem("firebaseToken"));
                    //console.log("Set key "+data.data['key']);
                }
            }
        },
        formatDatePicker(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = `0${date.getMinutes()}`.slice(-2);

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
        async getPlaces() {
            if (localStorage.getItem("places") != null) {
                this.places = JSON.parse(localStorage.getItem("places"));
                this.places.forEach(place => {
                    if (this.placeId == place.id) {
                        this.selectedPlace = place;
                    }
                });
            } else {
                const res = await fetch(
                    this.apiEndpoint + '/?m=listPlaces'
                );
                const data = await res.json();

                if (data.response == "success") {
                    this.places = data.data;
                    localStorage.setItem("places", JSON.stringify(data.data));
                    this.places.forEach(place => {
                        if (this.placeId == place.id) {
                            this.selectedPlace = place;
                        }
                    });
                }
            }
        },
        removeWikiMarkdown(data) {
            data = data.replace(new RegExp('\\r?\\n', 'g'), '<br/>');
            data = data.replace(/\]/g, "");
            data = data.replace(/\|/g, " ");
            //console.log(data);
            data = data.split(" ");
            data = data.filter(str => !str.includes("[club"));
            data = data.filter(str => !str.includes("[id"));
            let newWords;

            data.forEach(element => {
                if (newWords == undefined) {
                    newWords = element;
                } else {
                    newWords = newWords + " " + element;
                }
            });

            return newWords;
        },
        isValidHttpUrl(string) {
            let url;
            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },
        async addEvent() {
            let interesting;
            if (this.interesting) {
                interesting = 1;
            } else {
                interesting = 0;
            }
            const postData = {
                id: this.eventid,
                name: this.name,
                description: this.description,
                place: this.selectedPlaceId,
                site: this.eventSite,
                showen: this.isShowen,
                interesting: interesting,
                token: this.token,
                price: this.price,
                recommended: this.recommended,
                start_date: this.timestamp / 1000,
                instagram: this.instagramLink,
                local_image: this.newImageId,
                tags: JSON.stringify(this.returnTagsIds())
            }
            const res = await fetch(
                this.apiEndpoint + `/?m=addEvent`
                , {
                    method: 'POST',
                    headers: { "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
                    body: JSON.stringify(postData)
                });
            const data = await res.json();

            if (data.response == "success") {
                this.sendToast("✅ Успешно добавлено!");
            } else {
                console.log(data);
                this.sendToast("Error: " + data.data);
            }
        },
        returnTagsIds() {
            let tags = [];
            this.selectedTags.forEach(tag => {
                tags.push(tag.id);
            })

            return tags
        },
        async deleteEvent() {
            if (this.deleteCount == 2) {
                const res = await fetch(
                    `http://10.13.37.24/?m=hideEvent&id=${this.eventid}&token=${this.token}`
                );
                const data = await res.json();
                if (data.response == "success") {
                    this.sendToast("✅ Успешно скрыто с сайта!");
                    this.$router.go(-1);
                } else {
                    this.sendToast("Error: " + data.data.message);
                }
            } else {
                this.deleteCount = this.deleteCount + 1;
                this.sendToast("⁉️ Чтобы скрыть эвент нажмите на кнопку еще " + (2 - this.deleteCount) + " раз(а)");
            }
        },
        getTokenFromCookies() {
            let name = "token" + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return null;
        },
        async checkToken() {
            let token = this.getTokenFromCookies();

            if (token) {
                const res = await fetch(
                    this.apiEndpoint + '/?m=checkToken&token=' + token
                ).catch(function (err) {
                    console.log(err);
                });
                const data = await res.json();

                if (data.response == "success") {
                    this.isAdmin = true;
                    this.token = token;
                } else {
                    this.isAdmin = false;
                }
            }
        },
        sendToast(message) {
            createToast(message,
                {
                    hideProgressBar: true,
                    showCloseButton: false,
                    swipeClose: false,
                    position: 'top-center',
                })
        },
        updateSelectedEvent() {
            this.places.forEach(place => {
                if (this.placeId == place.id) {
                    this.selectedPlace = place;
                }
            })
        },
        linkify(inputText) {
            var replacedText, replacePattern1, replacePattern2, replacePattern3;

            //URLs starting with http://, https://, or ftp://
            // eslint-disable-next-line
            replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
            replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

            //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
            // eslint-disable-next-line
            replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
            replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

            //Change email addresses to mailto:: links.
            // eslint-disable-next-line
            replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
            replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

            return replacedText;
        },
        handleFileUpload(event) {
            this.selectedFileUrl = window.URL.createObjectURL(event.target.files[0]);
        },
        async uploadFile(callback) {
            const { canvas } = this.$refs.cropper.getResult();
            if (!this.selectedFileUrl) {
                this.sendToast('Ты не выбрал файл.');
                callback();
            }
            if (canvas) {
                canvas.toBlob( async blob => {
                    const formData = new FormData();
                    formData.append('file', blob, 'img.jpg');
                    try {
                        const response = await fetch(this.apiEndpoint + '/upload_image.php', {
                            method: 'POST',
                            body: formData,
                        });

                        if (!response.ok) {
                            this.sendToast('Произошла ошибка при загрузке файла. Попробуйте ещё раз.');
                            callback();
                        }
                        const result = await response.json();
                        if (result.response === 'error') {
                            this.sendToast(result.data);
                            callback();
                        } else if (result.response === 'success') {
                            this.sendToast('Файл успешно загружен.');
                            this.newImageId = result.data;
                            callback();
                        }
                    } catch (error) {
                        this.sendToast('Произошла ошибка при загрузке файла. Попробуйте ещё раз.' + error);
                        callback();
                    }
                }, 'image/jpeg');
            } else {
                this.sendToast('Ты не выбрал файл.');
                callback();
            }
        },
        async submit(){
            this.uploadFile(() => {
                if(this.newImageId) {
                    this.addEvent();
                } else {
                    this.sendToast('Произошла ошибка при загрузке файла. Попробуйте ещё раз.');
                }
            })
        },
        change({ coordinates, canvas }) {
            console.log(coordinates, canvas)
        }
    }
}

</script>

<style scoped>
.cropper {
    height: 600px;
    width: 600px;
    background: #DDD;
}

.eventBackground {
    background-size: 100%;
}

pre {
    white-space: pre-wrap;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.afisha {
    width: 100px;
    height: 100px;
    border-radius: 16px !important;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.center {
    text-align: center;
}

.oneLineText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#map {
    width: 100%;
    height: 200px;
    border-radius: 16px;
    position: relative;
    z-index: 500;
    border: 1px solid rgba(0, 0, 0, .125)
}

.card {
    border-radius: 16px;
}

.marker {
    background-image: url('http://localhost:8080/logo.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 500000;
}

.tagPadding {
    padding: 2px;
}

.spacer {
    height: 100px;
}

.hidden {
    display: none;
}

.artistImage {
    text-align: center;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.playButton {
    font-size: 2rem;
    color: white;
}

.eventInfo {
    padding-left: 0.7rem;
}

.artistCard {
    display: inline-block;
    display: flex;
}

.artistText {
    padding-left: 0.7rem;
}

.artistTags {
    font-family: 'Neue', 'Nunito Sans', sans-serif;
}

.noBorder {
    border: 0px !important;
    padding-bottom: 0;
    background: #FCFDFF;
}

.reaction {
    font-family: 'Neue', 'Nunito Sans', sans-serif;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.reactionButton {
    background-color: transparent;
    border: 0px;
    color: var(--font-color);
    animation: none;
}

/*.reactionButton:active{
    animation: bounce 0.2s;
    transform-origin: center bottom;
}*/
@keyframes bounce {

    0%,
    20%,
    53%,
    80%,
    100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0, 0, 0);
        transform-style: preserve-3d;
    }

    40%,
    43% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -30px, 0);
        transform-style: preserve-3d;
    }

    70% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -15px, 0);
        transform-style: preserve-3d;
    }

    90% {
        transform: translate3d(0, -4px, 0);
        transform-style: preserve-3d;
    }
}
</style>