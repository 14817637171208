<template>
<TitleComponent title="карта"></TitleComponent>

<div id="mapMenu">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-6 mb-4">
                <div class="card selectDateMenu">
                    <nav class="nav nav-pills nav-fill">
                        <a id="today" class="flex-sm-fill text-sm-center nav-link active" v-on:click="this.getCurrentDateButton()">🔥 Сегодня</a>
                        <a id="tommorow" class="flex-sm-fill text-sm-center nav-link" v-on:click="this.setTommorow()">Завтра</a>
                        <a id="aftertommorow" class="flex-sm-fill text-sm-center nav-link" v-on:click="this.setAfterTommorow()">Послезавтра</a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map"></div>

</template>

<script>

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import TitleComponent from '../components/TitleComponent.vue';
import router from '../router';

export default {
  name: 'MapView',
  components: {
      TitleComponent
  },
  data(){
    return {
        events: null,
        lastLoadedPage: null,
        maxPages: null,
        loading: true,
        maxPagesLoaded: false,
        currentTime: null,
        startDate: null,
        endDate: null,
        nothing: false,
        markers: null,
        darkTheme: false,
        zoomLevel: 12
    }
  },
    watch: {
        events: function() {
            if(this.events.length == 0 || this.events == null){
                this.nothing = true;
            } else {
                this.nothing = false;
            }
        }
    },
    activated(){
        if(window.Telegram && window.Telegram.WebApp){
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })
        }
    },
    async mounted(){
        if(window.Telegram && window.Telegram.WebApp){
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })
            if(window.Telegram.WebApp.colorScheme == "dark"){
            this.darkTheme = true;
            } else {
            this.darkTheme = false;
            }
        }
        this.getCurrentDate();
        this.renderLeaflet();
    },
    methods: {
        async renderLeaflet(){
            this.map = L.map('map', { zoomControl: false }).setView([43.24, 76.92], 12);

            let mapStyle;

            if (this.darkTheme) {
                mapStyle = 'mapbox/dark-v10';
            } else {
                mapStyle = 'mapbox/streets-v11';
            }

            this.markers = L.layerGroup();

            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}@2x?access_token={accessToken}', {
                attribution: ''/*'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'*/,
                maxZoom: 18,
                id: mapStyle,
                tileSize: 512,
                zoomOffset: -1,
                accessToken: 'pk.eyJ1IjoiYW5keWRvcmlhbiIsImEiOiJjam40ajdmc2MyOHY1M2twbGFydHl5MzlkIn0.qmBgiNmI8NFSArOC8cebsQ'
            }).addTo(this.map);

            this.map.attributionControl.setPrefix('')
            this.map.on("zoomend", this.onZoomEnd);

            let vm = this;
            setInterval(function () {
                vm.map.invalidateSize();
            }, 100);

            this.map.addLayer(this.markers);
        },
        onZoomEnd() {
            this.markers.clearLayers();
            console.log(this.map.getZoom())
            if(this.map.getZoom() < 14){
                let vm = this
                this.events.forEach(element => {
                    if(element && element.place && element.place.latitude && element.place.latitude != "undefined"){
                        let time = new Date(element.start_date * 1000);
                        time = time.getHours()+":"+String(time.getMinutes()).padStart(2, '0');
                        vm.addMarker(element.place.latitude, element.place.longitude, element.name, element.id, element.local_image, time, element.place.name, true);
                    } 
                })
            } else {
                let vm = this;
                this.events.forEach(element => {
                    if(element && element.place && element.place.latitude && element.place.latitude != "undefined"){
                        let time = new Date(element.start_date * 1000);
                        time = time.getHours()+":"+String(time.getMinutes()).padStart(2, '0');
                        vm.addMarker(element.place.latitude, element.place.longitude, element.name, element.id, element.local_image, time, element.place.name, false);
                    } 
                })
            }
        },
        addMarker(lat, lng, name, id, image, time, place, sm = false){
            let icon;
            if (sm) {
                icon = L.divIcon({
                    className: 'custom-div-icon',
                    html: "<a href='"+this.frontEndpoint+"/event/"+id+"' class='mapLink'><div class='eventCard'><img src='"+this.apiEndpoint+"/images/"+image+".jpg' width='50px' height='50px' class='eventCardImage'></div>",
                    iconSize: [30, 42],
                    iconAnchor: [15, 42]
                });
            } else {
                icon = L.divIcon({
                    className: 'custom-div-icon',
                    html: "<a href='"+this.frontEndpoint+"/event/"+id+"' class='mapLink'><div class='eventCard'><img src='"+this.apiEndpoint+"/images/"+image+".jpg' width='50px' height='50px' class='eventCardImage'><div class='mapText'><b>"+name+"</a></b><br>"+place+" в "+time+"</div></div>",
                    iconSize: [30, 42],
                    iconAnchor: [15, 42]
                });
            }
            let marker = L.marker([lat, lng], {
                icon: icon
            });

            this.markers.addLayer(marker);
        },
        async getEvents(page){
        if(page == null){
            this.features = [];
            const res = await fetch(
                this.apiEndpoint+'/?m=showEvents&startDate='+this.startDate
            );
            const data = await res.json();  
            if(data.response == "success"){

            if(data.data.length == 0){
                this.loading = false;
                return;
            }
            this.events = [];
            let vm = this;
            data.data.forEach(element => {

                if(element.name.length > 20){
                    element.name = element.name.substring(0, 15) + "...";
                }
                if(element.start_date > this.endDate){
                    return
                }
                if(element && element.place && element.place.latitude && element.place.latitude != "undefined"){
                    this.events.push(element);
                    let time = new Date(element.start_date * 1000);
                    time = time.getHours()+":"+String(time.getMinutes()).padStart(2, '0');

                    vm.addMarker(element.place.latitude, element.place.longitude, element.name, element.id, element.local_image, time, element.place.name, true);
                } 
            });
            this.lastLoadedPage = data.page;
            this.maxPages = data.pages;
            } 
        }
        },
        getCurrentDate(){
            var now = new Date();
            var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            this.currentTime = startOfDay / 1000;
            this.startDate = this.currentTime;
            this.endDate = this.currentTime + 86400;

            this.removeIdActive("tommorow");
            this.removeIdActive("aftertommorow")
            this.setIdActive("today");
            this.getEvents();
        },
        async getCurrentDateButton(){
            this.currentTime = + new Date();
            this.currentTime = this.currentTime / 1000;
            this.startDate = this.currentTime;
            this.endDate = this.currentTime + 86400;

            this.removeIdActive("tommorow");
            this.removeIdActive("aftertommorow")
            this.setIdActive("today");
            await this.clearLayers();
            await this.getEvents();
        },
        async setTommorow(){
            let tommorow = new Date();
            tommorow.setDate(tommorow.getDate() + 1);
            tommorow.setHours(0, 0, 0);
            tommorow.setMilliseconds(0);
            tommorow = tommorow / 1000;
            this.startDate = tommorow;
            this.endDate = tommorow + 86400;

            this.removeIdActive("today");
            this.removeIdActive("aftertommorow")
            this.setIdActive("tommorow");
            await this.clearLayers();
            await this.getEvents();
        },
        async clearLayers(){
            this.markers.clearLayers();
        },
        async setAfterTommorow(){
            let tommorow = new Date();
            tommorow.setDate(tommorow.getDate() + 1);
            tommorow.setHours(0, 0, 0);
            tommorow.setMilliseconds(0);
            tommorow = tommorow / 1000;
            this.startDate = tommorow + 86400;
            this.endDate = tommorow + 86400 + 86400;

            this.removeIdActive("today");
            this.removeIdActive("tommorow")
            this.setIdActive("aftertommorow");
            await this.clearLayers();
            await this.getEvents();
        },
        setIdActive(id){
            var element = document.getElementById(id);
            element.classList.add("active");
        },
        removeIdActive(id){
            var element = document.getElementById(id);
            element.classList.remove("active");
        }
    }
}

</script>

<style scoped>
.center{
  text-align: center;
}
.oneLineText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#map{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}
.marker {
  background-image: url('http://localhost:8080/logo.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 500000;
}
.tagPadding{
  padding: 2px;
}
#mapMenu {
    text-align: center;
    position: fixed;
    z-index: 3000;
    width: 100%;
    bottom: 0;
}

#mapMenuUp {
    position: fixed;
    z-index: 3000;
    width: 100%;
}

</style>