<template>
<TitleComponent title="лента"></TitleComponent>

<div class="header fixed-top">
  <div class="container mt-2 mb-2">
    <div class="row justify-content-center">
      <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-7">
        <div class="row align-self-center mt-2 pe-1 mb-2">

          <div class="col-4">
            <router-link id="bookmarks" to="/map">
              <button class="circle-btn">
                  <i class="bi bi-map"></i>
              </button>
            </router-link>
          </div>

          <div class="col-4 justify-content-center d-flex align-items-center" style="text-align: center;" v-if="this.darkTheme">
            <img src="assets/logo_dark.png" class="img-fluid" style="height: 2rem;" v-if="!isAdmin">
            <router-link id="admin" to="/admin" v-if="isAdmin">
              <img src="assets/logo_dark.png" class="img-fluid" style="height: 2rem;">
            </router-link>
          </div>

          <div class="col-4 justify-content-center d-flex align-items-center" style="text-align: center;" v-if="!this.darkTheme">
            <img src="assets/logo_light.png" class="img-fluid" style="height: 2rem;" v-if="!isAdmin">
            <router-link id="admin" to="/admin" v-if="isAdmin">
              <img src="assets/logo_light.png" class="img-fluid" style="height: 2rem;">
            </router-link>
          </div>

          <div class="col-4" style="text-align:right; white-space: nowrap;">
            <router-link id="bookmarks" to="/bookmarks">
              <button class="circle-btn">
                  <i class="bi bi-heart"></i>
              </button>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<br>

<div class="spacer"></div>

<div class="cardsRow">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-7 mb-3">
        <div class="row">
            <div class="input-group">
              <input v-on:input="searchAction()" v-model="search" type="text" class="form-control searchbar" placeholder="Поиск">
              <button type="button" v-on:click="clearSearch()" class="btn bg-transparent" style="margin-left: -40px; z-index: 100;" v-if="this.search != ''">
                <i class="bi bi-x-circle"></i>
              </button>
          </div>
          <div class="horizontalCards mt-2" v-if="this.search == ''">
            <span v-for="date in dates" :key="date">

              <span class="dateCardSelected" v-on:click="fillDate(date)" v-if="this.selectedDate == date">
                <small>{{getWeekDay(date)}}</small><br>
                {{getDayNumber(date)}}
              </span>

              <span class="dateCard" v-on:click="fillDate(date)" v-if="this.selectedDate != date">
                <small class="weekDay">{{getWeekDay(date)}}</small><br>
                {{getDayNumber(date)}}
              </span>

            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="container">
    <transition-group name="list" tag="span">
      <span v-for="(event, index) in events" :key="event.id">
          <span v-if="index > 1">
            <div class="row justify-content-center" v-if="returnDayName(event.start_date, events[index-1].start_date)">
              <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-7 mb-2">
                <div class="dateInformerOld">
                  <div class="row dateInformerInside">
                    <div class="col">
                      {{ returnDayNameTwo(event.start_date, events[index-1].start_date).toUpperCase() }} <span style="color:gray">{{ returnDayName(event.start_date, events[index-1].start_date).toUpperCase() }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        <EventComponent :index="index" :name="event.name" :start_date="event.start_date" :tags="event.tags" :local_image="event.local_image" :id="event.id" :placeName="event.placeName" :placeMetro="event.placeMetroName" :placeMetroColor="event.placeMetroColor" :interesting="event.interesting" :price="event.price" :artists="event.artists" :hidden="event.showen" :love="event.reactions.love" :cool="event.reactions.cool" :hmm="event.reactions.hmm" :poo="event.reactions.poo"></EventComponent>
      </span>
    </transition-group>

    <div class="center info" v-if="!this.events && !this.loading && !this.connectionError">
      <br>
      <h2>😞 Доступных мероприятий нет.</h2>
      <br>
    </div>

    <div class="center info" v-if="this.connectionError">
      <br>
      <h2>😞 Ошибка соединения с сервером</h2>
      <h3>С интернетом всё в порядке?</h3>
      <br>
    </div> 

    <div class="center info" v-if="this.loading">
      <br>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <br>
      <br>
    </div>

    <div class="center info" v-if="this.maxPagesLoaded">
      <br>
      <h2>🙇‍♀️</h2>
      <h2>Ты долистал до конца.</h2>
      <br>
    </div>
  </div>
</div>

</template>

<script>

import TitleComponent from '../components/TitleComponent.vue';
import EventComponent from "../components/EventComponent.vue";
import CustomToastComponent from "../components/CustomToastComponent.vue";
import { createToast, withProps } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import router from '../router';

export default {
  name: 'HomeView',
  components: {
    // eslint-disable-next-line
    EventComponent, TitleComponent, CustomToastComponent
  },
  data(){
    return {
      events: null,
      lastLoadedPage: null,
      maxPages: null,
      loading: true,
      maxPagesLoaded: false,
      connectionError: false,
      currentTime: null,
      search: "",
      datepicker: '',
      clickCount: 0,
      isApp: this.isApp,
      cards: null,
      isCardLoading: true,
      dates: null,
      realCurrentDate: null,
      selectedDate: null,
      darkTheme: false,
      showWhat: 'all',
      admins: ['drushadrusha'],
      isAdmin: false,
      hasScrolled: false,
      bgColor: null
    }
  },
  watch: {
    showWhat(){
      this.events = null;
      this.maxPages = null;
      this.lastLoadedPage = null;
      this.maxPagesLoaded = false;
      this.isLoading = true;
      this.getEvents();
    }
  },
  activated(){

    document.title = "лента - тусовка";

    if(window.Telegram && window.Telegram.WebApp){
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.MainButton.hide();
        window.Telegram.WebApp.setHeaderColor("secondary_bg_color")
        if(window.Telegram.WebApp.colorScheme == "dark"){
          this.darkTheme = true;
        } else {
          this.darkTheme = false;
        }
    }
  }, 
  async mounted(){
    if(window.Telegram && window.Telegram.WebApp){
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.SettingsButton.show();
      window.Telegram.WebApp.SettingsButton.onClick(async () => {
        router.push('/settings');
      })
      const user = window.Telegram.WebApp.initDataUnsafe?.user;
      this.bgColor = window.Telegram.WebApp.themeParams.secondary_bg_color;
      if (user && user.username && this.admins.includes(user.username)) {
        this.isAdmin = true
      }
    }
    window.addEventListener("scroll", this.handleScroll);
    this.getCurrentDate();
    if(this.events == null) {
      this.getEvents();
    }
    this.getNextPage();
    this.getDates();
  },
  methods: {
    async getEvents(page){
      this.isLoading = true;
      if(page == null){

        let url = this.apiEndpoint+'/?m=showEvents&startDate='+this.currentTime+'&q='+this.search;
        if(localStorage.getItem('admin') == 'true'){
          url = this.apiEndpoint+'/?m=showEvents&startDate='+this.currentTime+'&q='+this.search+"&showHidden=true";
        } 
        if(this.showWhat == 'interesting'){
          url = url + '&recommends=true';
        }
        const res = await fetch(
              url
          ).catch(()=>{
            this.loading = false;
            this.connectionError = true;
            return;
          });

        if(this.connectionError){
          return;
        }
        const data = await res.json();
        if(data.response == "success"){

          if(data.data.length < 1 && this.showWhat == 'interesting'){
            this.showWhat = 'all';
            this.getEvents();
            return;
          }

          if(data.data.length == 0){
            this.loading = false;
            this.events = null;
            return;
          }
          data.data.forEach(element => {
            if(element && element.place && element.place != "undefined"){
              element.placeName = element.place.name;
            }
            if(element && element.place && element.place.metro && element.place.metro != "undefined"){
              element.placeMetroName = element.place.metro.name;
              element.placeMetroColor = element.place.metro.color;
            }
            if(element.showen == 0){
              element.interesting = 1;
            }
            if(element.reactions.poo > (element.reactions.love + element.reactions.cool)){
              element.interesting = 1;
            }
            // check if element.tags is json
            element.tags = JSON.parse(element.tags);
            element.start_date = new Date(element.start_date * 1000);
            element.date = element.start_date.getDate()+" "+this.convertMonthToName(element.start_date.getMonth())+" "+element.start_date.getHours()+":"+String(element.start_date.getMinutes()).padStart(2, '0');
          });
          this.events = data.data;
          this.loading = false;
          this.lastLoadedPage = data.page;
          this.maxPages = data.pages;
        } 
      } else {
        let url = this.apiEndpoint+'/?m=showEvents&page='+page+'&startDate='+this.currentTime+'&q='+this.search
        if(localStorage.getItem('admin') == 'true'){
          url = this.apiEndpoint+'/?m=showEvents&page='+page+'&startDate='+this.currentTime+'&q='+this.search+"&showHidden=true";
        } 
        if(this.showWhat == 'interesting'){
          url = url + '&recomends=true';
        }
        const res = await fetch(
              url
          );
        const data = await res.json();  
        if(data.response == "success"){
          data.data.forEach(element => {

            if(element && element.place && element.place != "undefined"){
              element.placeName = element.place.name;
            }
            if(element && element.place && element.place.metro && element.place.metro != "undefined"){
              element.placeMetroName = element.place.metro.name;
              element.placeMetroColor = element.place.metro.color;
            }

            if(element.showen == 0){
              element.interesting = 1;
            }

            if(element.reactions.poo > (element.reactions.love + element.reactions.cool)){
              element.interesting = 1;
            }

            element.tags = JSON.parse(element.tags);
            element.start_date = new Date(element.start_date * 1000);
            element.date = element.start_date.getDate()+" "+this.convertMonthToName(element.start_date.getMonth())+" "+element.start_date.getHours()+":"+String(element.start_date.getMinutes()).padStart(2, '0');

            this.events.push(element);
            this.loading = false;
          });
          this.lastLoadedPage = data.page;
        }
      }
    },
    getNextPage() {
      window.onscroll = () => {
       if (document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight-100 && this.loading == false && this.maxPagesLoaded == false) {
          //console.log("Bottom of window");
          if(this.lastLoadedPage == this.maxPages){
            this.loading = false;
            this.maxPagesLoaded = true;
            //console.log("Max pages loaded");
          } else {
            this.loading = true;
            this.lastLoadedPage = this.lastLoadedPage + 1;
            this.getEvents(this.lastLoadedPage);
          }
        }
      }
    },
    setSearchString(string){
      this.search = string;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.events = null;
      this.lastLoadedPage = null;
      this.maxPages = null;
      this.maxPagesLoaded = false;
      this.isLoading = true;
      this.getEvents();
    },
    convertMonthToName(num){
      let monthA = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',');
      return monthA[num];
    },
    convertStringToColor(str) {
      var hash = 0;
      for (var a = 0; a < str.length; a++) {
        hash = str.charCodeAt(a) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    getCurrentDate(){
      var now = new Date();
      var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      this.currentTime = startOfDay / 1000;
    },
    searchAction(){
      this.currentTime = this.realCurrentDate;
      this.selectedDate = this.realCurrentDate;
      this.events = null;
      this.maxPages = null;
      this.lastLoadedPage = null;
      this.maxPagesLoaded = false;
      this.isLoading = true;
      this.getEvents();
    },
    formatDate(date) {
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('.');
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    clearSearch(){
      this.search = "";
      this.getEvents();
      this.selectedDate = this.realCurrentDate;
    },
    getDates(){
      this.dates = [];
      var now = new Date();
      var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      startOfDay = startOfDay / 1000;

      this.selectedDate = startOfDay;
      this.realCurrentDate = startOfDay;

      for(var i = 0; i < 24; i++) {
        this.dates.push(startOfDay + i * 3600 * 24);
      }
    },
    sendToast(message) {
          createToast(withProps(CustomToastComponent, { title: message }), {
            hideProgressBar: true,
            showCloseButton: false,
            swipeClose: false,
            transition: 'slide',
            showIcon: false,
            position: 'top-center',
          })
        },
    getWeekDay(timestamp){
      var days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      var d = new Date(timestamp*1000);
      return days[d.getDay()];
    },
    getWeekDayFull(timestamp){
      var days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
      var d = new Date(timestamp*1000);
      return days[d.getDay()];
    },
    getDayNumber(timestamp){
      var date = new Date(timestamp*1000);
      return date.getDate();
    },
    fillDate(date){
      this.selectedDate = date;
      this.events = null;
      this.currentTime = date;
      this.lastLoadedPage = null;
      this.maxPagesLoaded = false;
      this.isLoading = true;
      this.getEvents();
    },
    returnDayName(eventDate, previusEventDate){
      eventDate = new Date(eventDate);
      previusEventDate = new Date(previusEventDate);
      var startOfDayEventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
      var startOfDayPreviousEventDate = new Date(previusEventDate.getFullYear(), previusEventDate.getMonth(), previusEventDate.getDate());
      if(startOfDayEventDate.getTime() != startOfDayPreviousEventDate.getTime()){
        return startOfDayEventDate.getDate()+" "+ this.convertMonthToName(startOfDayEventDate.getMonth());
      }
    },
    returnDayNameTwo(eventDate, previusEventDate){
      eventDate = new Date(eventDate);
      previusEventDate = new Date(previusEventDate);

      var startOfDayEventDate = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
      var startOfDayPreviousEventDate = new Date(previusEventDate.getFullYear(), previusEventDate.getMonth(), previusEventDate.getDate());

      if(startOfDayEventDate.getTime() != startOfDayPreviousEventDate.getTime()){
        return this.getWeekDayFull(startOfDayEventDate);
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      const threshold = 3 * parseFloat(getComputedStyle(document.documentElement).fontSize); // 3rem in pixels
      this.hasScrolled = scrollPosition > threshold;
      document.getElementsByClassName("header")[0].classList.toggle("header-scrolled", this.hasScrolled);
    }
  }
}
</script>
<style>
.center{
  text-align: center;
}
.btn-outline-secondary{
  color: #000000 !important;
  border-color: #E85F5C !important;
}
.btn-outline-secondary.active{
  color: #ffffff !important;
  background-color: #E85F5C !important;
  border-color: #E85F5C !important;
}
.form-control:focus {
  border-color: #000000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 1px rgba(0, 0, 0, 0.6);
}
.list-item {
  display: inline-block;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.searchbar{
    width: 100%;
    border-radius: 16px;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
}
.searchbar:focus {
    outline: none !important;
}
.customButton:focus{
  outline: none;
}
.dateCard{
  font-family: 'Neue', 'Nunito Sans', sans-serif;
  cursor: pointer;
  margin: 0.3rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  text-align: center;
}
.dateCardSelected{
  font-family: 'Neue', 'Nunito Sans', sans-serif;
  border-radius: 16px;
  cursor: pointer;
  margin: 0.3rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  text-align: center;
}
.weekDay{
  color: var(--tg-theme-hint-color);
}
.infoCard{
  color: white;
  height: 7rem;
  width: 7rem;
  position: relative;
  border-radius: 16px;
  margin: 0.3rem;
  display: inline-block;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: transform .2s;
}
.infoCard:hover{
  transform: scale(0.95);
}
.infoCardLoading{
  height: 8rem;
  width: 7rem;
  position: relative;
  border-radius: 16px;
  margin: 0.3rem;
  display: inline-block;
}
.infoCardText{
  position: absolute;
  padding: 0.5rem;
  bottom: 0;
  left: 0;
  white-space: normal;
}
.horizontalCards{
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}
.horizontalCards::-webkit-scrollbar {
  display: none;
}
.cardLink{
  color: white;
}
.menuButton{
  color: black;
}
.dateInformer{
  font-family: 'Neue', 'Nunito Sans', sans-serif;
  background-color: var(--card-background);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  /*border: 1px solid #ced4da !important;*/
}
.dateInformerInside{
  padding: 1rem;
}
.selector{
  font-family: 'Neue', 'Nunito Sans', sans-serif;
  border-radius: 16px;
  color: black !important;
  cursor: pointer;
}
.nav-link{
  color: var(--color-text) !important;
}
.selectorButtonActive{
  color: var(--color-text) !important;
  border-radius: 16px !important;
  background-color: var(--card-background) !important;
  /*border: 1px solid #ced4da !important;*/
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.header{
  background-color: var(--tg-theme-secondary-bg-color);
  transition: all 0.3s ease-in-out;
}

.header-scrolled{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.spacer{
  height: 4rem;
}
</style>