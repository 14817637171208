<template>
  <router-link :to="`/event/${this.id}`">
  <div class="event" ref="event">
    <div class="row justify-content-center">
      <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-7 mb-2">
        <div class="row g-0">
          <div class="col-auto">
            <img v-if="this.interesting === 0" :src="this.apiEndpoint+'/images/'+this.local_image+'.jpg'" class="img-fluid rounded afisha" :alt="this.name">
            <img v-if="this.interesting === 1" :src="this.apiEndpoint+'/images/'+this.local_image+'.jpg'" class="img-fluid rounded afishaSmall" :alt="this.name">
          </div>
          <div class="col-8">
            <div class="card-body">
              <span class="card-text"><small class="text-muted dateText">{{this.date}}</small></span>
                <h6 class="card-title oneLineText eventTitle"><b>{{this.name}}</b></h6>
              <span class="card-text oneLineText placeText" v-if="this.placeName && this.interesting === 0"><small class="text-muted">{{this.placeName}}</small><br></span>
              <div class="tags" v-if="this.interesting === 0">
                <span class="oneLineText" >
                  <span  v-if="this.price">
                    <span class="badge tagElement" v-bind:style="{backgroundColor:this.convertStringToColor(this.price),color:this.getTagTextColor(this.price)}">
                      {{this.price}}
                    </span>
                    <span class="tagPadding"></span>
                  </span>

                  <span v-for="artist in this.artists.slice(0, 1)" :key="artist">
                    <span class="badge tagElement" v-bind:style="{backgroundColor:this.convertStringToColor(artist.name),color:this.getTagTextColor(artist.name)}">
                      {{artist.name}}
                    </span>
                    <span class="tagPadding"></span>
                  </span>
                  <span v-if="this.artists.length > 1"><small>и еще {{this.artists.length - 1}}</small></span>

                <span v-if="this.artists.length == 0">
                <span class="badge tagElement" v-if="love > 0" style="background-color:#C0AED6; color:#4c4555;">
                    😍 {{love}}
                  </span>
                  <span class="tagPadding"></span>
                  <span class="badge tagElement" v-if="cool > 0" style="background-color:#00A1E5; color:#00405b;">
                    😎 {{cool}}
                  </span>
                  <span class="tagPadding"></span>
                  <span class="badge tagElement" v-if="hmm > 0" style="background-color:#4B7904; color:#1e3001;">
                    🤔 {{hmm}}
                  </span>
                  <span class="tagPadding"></span>
                  <span class="badge tagElement" v-if="poo > 0" style="background-color:#937B3D; color:#3a3118;">
                    💩 {{poo}}
                  </span>

                </span>

                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </router-link>
</template>

<script>
export default {
  name: 'EventComponent',
  props: {
    name: String,
    img: String,
    start_date: Date,
    description: String,
    tags: Array,
    local_image: String,
    id: Number,
    placeName: String,
    placeMetro: String,
    placeMetroColor: String,
    interesting: Number,
    price: String,
    artists: Array,
    hidden: {
      type: Number,
      default: 0
    },
    love: Number,
    cool: Number,
    poo: Number,
    hmm: Number,
  },
  data(){
    return {
      date: null
    }
  },
  mounted(){
    //console.log(this.shadeColor('#937B3D', -60));
    if(this.start_date){
      this.date = new Date(this.start_date * 1000);
      this.date = this.start_date.getDate()+" "+this.convertMonthToName(this.start_date.getMonth())+" "+this.start_date.getHours()+":"+String(this.start_date.getMinutes()).padStart(2, '0');
    }
    if(this.hidden == 0){
      // set faded class to event 
      this.$refs.event.classList.add('faded');
    }
  },
  methods:{
    convertMonthToName(num){
      let monthA = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',');
      return monthA[num];
    },
    convertStringToColor(str) {
      var hash = 0;
      for (var a = 0; a < str.length; a++) {
        hash = str.charCodeAt(a) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        if(value < 128){
          value = value + 128;
        }
        if(value > 160){
          value = value - 20;
        }
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    shadeColor(color, percent) {

      var R = parseInt(color.substring(1,3),16);
      var G = parseInt(color.substring(3,5),16);
      var B = parseInt(color.substring(5,7),16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;  
      G = (G<255)?G:255;  
      B = (B<255)?B:255;  

      var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
    },
    getTagTextColor(string) {
      let color = this.convertStringToColor(string);
      return this.shadeColor(color, -60);
    }
  }
}
</script>

<style scoped>
a{
  color: black;
  text-decoration: none;
}
.afisha {
  width: 6rem;
  height: 6rem;
  border-radius: 16px !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.afishaSmall {
  width: 50px;
  height: 50px;
  border-radius: 16px !important;
}
.oneLineText{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-body{
  padding: 0;
  padding-left: 1rem;
}
.tagPadding{
  padding: 2px;
}
/*.dateText{
  /*font-size: 0.8rem;*/
  /*margin-top: 0 !important;
  padding: 0 !important;*/
/*}*/
.eventTitle{
  /*font-size: 0.9rem;*/
  margin-bottom: 0.2rem !important;
  /*font-weight: 300;*/
}
/*.placeText{
  /*font-size: 0.9rem;*/
/*}*/
.tagElement{
  font-family: 'Neue', 'Nunito Sans', sans-serif;
  margin-top: 0.5rem !important;
}
.card-body{
  padding-left: 0.7rem;
}
.faded{
  opacity: 0.5;
}
</style>
