import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EventView from '../views/EventView.vue'
import MapView from '../views/MapView.vue'
import AdminView from '../views/AdminView.vue'
import SettingsView from '../views/SettingsView.vue'
import CardFullTextView from '../views/CardFullTextView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import AddEventView from '../views/AddEventView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/event/:eventid',
    name: 'event',
    component: EventView,
    props: true
  },
  { path: '/info/:info',
    name: 'info',
    component: CardFullTextView,
    props: true
  },
  {
    path: '/map',
    name: 'map',
    component: MapView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/addEvent',
    name: 'aaddEvent',
    component: AddEventView
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BookmarksView.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
})

export default router
