<template>

<div class="container">
  <div class="row justify-content-center">
      <div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-6 mt-3">
  <div class="card">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <label for="customRange1" class="form-label"><span class="square-icon" style="background-color: #FF3B30;"><i class="bi bi-volume-up"></i></span> Громкость превью</label>
        <input type="range" class="form-range" id="customRange1" v-model="appVolume" v-on:change="changeAppVolume()">
      </li>

      <!--<li class="list-group-item" style="cursor: pointer;">
        <span class="square-icon" style="background-color: #4CD863;"><i class="bi bi-lock"></i></span> <span v-on:click="clearAuth()">Очистить данные</span>
      </li>-->
    </ul>
  </div>
  <br>
  <div class="card">
    <div class="card-body">
      Используются данные &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, Картографические изображения - © <a href="https://www.mapbox.com/">Mapbox</a>
    </div>
  </div>


  <br>
</div>
</div>
</div>

</template>

<script>

import router from '../router';

export default ({
  components: {
  },
  data() {
    return {
      appVolume: null
    }
  },
  activated(){
        if(window.Telegram && window.Telegram.WebApp){
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })
        }
    },
  mounted(){

    this.appVolume = localStorage.getItem('appVolume') || 70;

  },
  methods: {
    changeAppVolume(){
      localStorage.setItem('appVolume', this.appVolume);
    },
    clearAuth(){
      // delete all cookies and local storage
      localStorage.clear();
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    }
    },
  }
)
</script>

<style scoped>
.center{
  text-align: center;
}
.secretWords{
  cursor: pointer;
}
.form-switch {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}
</style>