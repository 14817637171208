<template>

<div class="container">
<div class="center">
<img src="/assets/wild.webp">
<br>
</div>

<div class="row justify-content-center">
<div class="col-12 col-xs-6 col-md-12 col-l-6 col-xl-6 col-xxl-6 mb-3">
<div class="center" v-if="!this.isLogined">
  <br>
  Логин:<br>
  <input type="text" class="form-control" v-model="login" autocomplete="username" disabled>
  Пароль:<br>
  <input type="password" class="form-control" v-model="password" autocomplete="current-password"><br>
  <button type="submit" class="w-100 btn btn-primary btn-lg" v-on:click="this.authorize()">Войти</button>
  <br>
</div>


<div v-if="this.isLogined">
  <div class="card">
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="row">
            <div class="col-10" v-on:click="this.showAnnotations = !this.showAnnotations" style="cursor: pointer;">
              <span class="square-icon" style="background-color: #35AADC;"><i class="bi bi-question-circle-fill"></i></span> Показывать подсказки
            </div>
            <div class="col-2">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="darkTheme"
                        v-model="this.showAnnotations">
                </div>
            </div>
        </div>
      </li>
      <li class="list-group-item" v-on:click="this.$router.push('/addEvent')" style="cursor: pointer;">
          <span class="square-icon" style="background-color: #FF3B30;"><i class="bi bi-calendar2-plus-fill"></i></span> Добавить событие
      </li>

      <li class="list-group-item" v-on:click="this.saveToken(null) && this.clearCookies() && this.clearTelegramKey()" style="cursor: pointer;">
        <span class="square-icon" style="background-color: #4CD863;"><i class="bi bi-lock"></i></span> Выйти
      </li>
    </ul>
  </div>
</div>

</div>
</div>

</div>


</template>

<script>

import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import router from '../router';

export default ({
  components: {
  },
  data() {
    return {
      bookmarks: null,
      events: [],
      loading: true,
      noBookmarks: false,
      login: null,
      password: null,
      userToken: null,
      isLogined: false,
      showAnnotations: true
    }
  },
  activated(){
        if(window.Telegram && window.Telegram.WebApp){
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })
        }
    },
  watch: {
    showAnnotations: function(){
      localStorage.setItem("showAnnotations", this.showAnnotations);
    }
  },
  mounted(){
    this.checkToken();
    if(localStorage.getItem("showAnnotations") === null){
      this.showAnnotations = true;
      localStorage.setItem("showAnnotations", this.showAnnotations);
    } else {
      this.showAnnotations = localStorage.getItem("showAnnotations");
    }
    if(window.Telegram && window.Telegram.WebApp){
            this.isTelegram = true;
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(async () => {
                router.go(-1);
            })

            window.Telegram.WebApp.ready();
            const user = window.Telegram.WebApp.initDataUnsafe?.user;
            if (user && user.username) {
              this.login = user.username
            }
        }
  },
  methods: {
    getTokenFromCookies(){
        let name = "token" + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return null;
    },
    async authorize(){
      const res = await fetch(
          this.apiEndpoint+'/?m=authorize&username='+this.login+'&password='+this.password
      );
      const data = await res.json();  
      
      if(data.response == "success"){
        console.log(data.data);
        this.userToken = data.data.token;
        window.localStorage.setItem("admin-token", this.userToken);
        window.localStorage.setItem("admin", "true");
        this.saveToken(this.userToken);
        this.isLogined = true;
        console.log("Successfully logged in")
      }else {
        this.sendToast("Error: " + data.data);
        window.localStorage.setItem("admin", "false");
      }
    },
    async clearTelegramKey(){
      if(window.Telegram && window.Telegram.WebApp){
        window.Telegram.WebApp.CloudStorage.removeItem('key');
      }
    },
    async checkToken(){
      let token = this.getTokenFromCookies();
      console.log("Found token: " + token);
      const res = await fetch(
          this.apiEndpoint+'/?m=checkToken&token='+token
      );
      const data = await res.json();  

      if(data.response == "success"){
        console.log("Success!");
        this.isLogined = true;
      }else {
        this.isLogined = false;
        console.log("Error: " + data.data);

      }
    },
    saveToken(token){
      if(token == null) {
        this.isLogined = false;
      }
        const d = new Date();
        let exdays = 360;
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        let cname = "token"

        document.cookie = cname + "=" + token + ";" + expires + ";path=/";
    },
    clearCookies(){
        document.cookie = "";
    },
    sendToast(message){
      createToast(message,
        {
          hideProgressBar: true,
          showCloseButton: false,
          swipeClose: false,
          position: 'bottom-center',
        })
    }
  }
})
</script>

<style scoped>
.center{
  text-align: center;
}
</style>