<template>
  <router-view v-slot="{ Component }">
    <keep-alive exclude="TitleComponent,EventView,EventComponent,BookmarksView,CardFullTextView,SettingsView">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import router from './router'

export default {
  data(){
    return{
      darkTheme: false,
      userKey: this.userKey,
    }
  },
  mounted(){
    if(window.Telegram && window.Telegram.WebApp){
            window.Telegram.WebApp.expand();
            window.Telegram.WebApp.disableVerticalSwipes()
            let startParam = window.Telegram.WebApp.initDataUnsafe.start_param || null;
            if(startParam != null){
              router.push('/event/' + startParam);
            }
            if(window.Telegram.WebApp.initData == ''){
              document.body.classList.add("fallback-theme");
            }
    }
  },
  methods: {
  }
  }
</script>

<style>

.fallback-theme {
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-hint-color: #999999;
  --tg-theme-link-color: #d45083;
  --tg-theme-button-color: #d45083;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #efeff3;
  --tg-theme-header-bg-color: #efeff3;
  --tg-theme-bottom-bar-bg-color: #e4e4e4;
  --tg-theme-accent-text-color: #d45083;
  --tg-theme-section-bg-color: #ffffff;
  --tg-theme-section-header-text-color: #6d6d71;
  --tg-theme-section-separator-color: ;
  --tg-theme-subtitle-text-color: #999999;
  --tg-theme-destructive-text-color: #ff3b30;
}
html{
  background: var(--tg-theme-secondary-bg-color);
  scroll-behavior: auto !important;
}

body{
  background: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  scroll-behavior: auto !important;
}

h6{
  color: var(--tg-theme-text-color);
}

.text-muted{
    color: var(--tg-theme-hint-color) !important;
}

.dateCardSelected{
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
}

.noBorder{
    background: var(--tg-theme-section-bg-color) !important;
}

.btn-sm{
  border-radius: 20px !important;
}

.btn{
  border-radius: 12px !important;
}
.default{
  border-radius: 16px !important;
}
.btn-lg{
  border-radius: 10px !important;
}

.btn-primary{
    background-color: var(--tg-theme-button-color) !important;
    border-color: var(--tg-theme-button-color) !important;
    color: var(--tg-theme-button-text-color) !important;
}

.bg-transparent{
  color: var(--tg-theme-text-color);
}

.btn-danger {
  background-color: var(--tg-theme-destructive-text-color) !important;
  border-color: var(--tg-theme-destructive-text-color) !important;
  color: var(--tg-theme-button-text-color) !important;
}

.btn-secondary{
  background-color: var(--tg-theme-section-bg-color) !important;
  border-color: var(--tg-theme-button-color) !important;
  color: var(--tg-theme-text-color) !important;
}

input[type="text"], input[type="password"], textarea {
  background-color: var(--tg-theme-section-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
  border: 1px solid var(--tg-theme-section-separator-color) !important;
}

input:focus, textarea:focus, select:focus{
  border: 1px solid var(--tg-theme-section-separator-color) !important;
}

.form-control{
  border-radius: 12px !important;
}

.card{
    background: var(--tg-theme-section-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
    border-radius: 12px !important;
}

.card-body{
  color: var(--tg-theme-text-color) !important;
}

.menuButton{
    color: var(--tg-theme-text-color) !important;
}

#app {
  background: var(--tg-theme-secondary-bg-color);
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
  font-size: 0.9rem;
  font-display: swap;
  --vs-dropdown-bg: var(--tg-theme-section-bg-color);
  --vs-dropdown-color: var(--tg-theme-text-color);
  --vs-border-color: var(--tg-theme-section-separator-color);
  --vs-selected-bg: var(--tg-theme-button-color);
  --vs-selected-color: var(--tg-theme-button-text-color);
  --vs-border-radius: 12px;
}
.dp__theme_light {
  --dp-background-color: var(--tg-theme-section-bg-color);
  --dp-border-color: var(--tg-theme-section-separator-color);
  --dp-text-color: var(--tg-theme-text-color);
  --dp-border-radius: 12px;
}
@font-face {
    font-family: 'Neue';
    src: url('@/assets/fonts/PPNeueMachina-InktrapRegular.woff2') format('woff2'),
         url('@/assets/fonts/PPNeueMachina-InktrapRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

.list-group-item{
    background: var(--tg-theme-section-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
}


#divfix {
  bottom: 0;
  text-align: center;
  position: fixed;
  z-index: 3000;
  width: 100%;
}
.list-group-item:last-child{
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
.list-group-item:first-child{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.list-group-item{
  font-size: 1rem;
}
.form-switch .form-check-input:checked {
  height: 1rem;
  width: 2.5rem;
}

.form-switch .form-check-input{
  height: 1rem;
  width: 2.5rem;
}
.menu {
  border-radius: 12px !important;
  border: 2px solid #18191F;
  filter: drop-shadow(0px 4px 0px #18191F);
}
.nav-pills > a {
    color: var(--tg-theme-section-header-text-color) !important;
}
.nav-pills > a.active {
    color: var(--tg-theme-button-text-color) !important;
    background-color: var(--tg-theme-button-color) !important;
    border-radius: 12px;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.badge{
  border-radius: 44px !important;
  font-family: 'Neue', 'Ubuntu', sans-serif;
}
.eventCard{
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
  display: inline-block;
  color: black;
  white-space: nowrap;
}
.eventCardImage{
  border-radius: 50%;
  border: 1px solid #18191F;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.mapLink:link {
  color: var(--tg-theme-text-color);
  text-decoration: none;
}
.mapLink:visited {
  color: var(--tg-theme-text-color);
  text-decoration: none;
}
.mapText{
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: var(--tg-theme-section-bg-color);
  color: var(--tg-theme-text-color);
  border-radius: 12px;
  border: 0px solid #18191F;
  padding: 0.4rem;
  margin-top: 0.3rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.v-select{
  background-color: var(--tg-theme-section-bg-color);
  color: var(--tg-theme-text-color);
  border-radius: 12px;
}

.circle-btn {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: var(--tg-theme-section-bg-color);
    color: var(--tg-theme-text-color);
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.2rem;
}

.dropdown-menu {
  background-color: var(--tg-theme-section-bg-color);
  color: var(--tg-theme-text-color);
  --bs-dropdown-link-color: var(--tg-theme-text-color) !important;
  --bs-dropdown-link-active-color: var(--tg-theme-button-text-color) !important;
  --bs-dropdown-link-active-bg: var(--tg-theme-button-color) !important;
  border-radius: 12px;
}

.square-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem; /* Customize the background color */
      color: white; /* Icon color */
      font-size: 1rem; /* Customize icon size */
      border-radius: 5px; /* Add rounded corners */
      margin-right: 0.5rem; /* Add some spacing between the icon and text */
}

</style>
